export default function ChangesLog() {
    return (
        <main>
            <section className='textView'>
                <h1>Changes Log</h1>

                <div className='parallelogramSeparator'>
                </div>
                <article>
                    <h2>Web App v3.1, July, 2024</h2>
                    <p>Big update with lots of functionality added and the second subscription plan unveiled – "Le Chat" which includes saved replies, automatic replies and playbooks.</p>
                    <ul>
                        <li>saved replies, save once use any moment</li>
                        <li>automatic replies, trigger by visitor's message keywords and optonally URL matching</li>
                        <li>playbooks, proactively triggered messages based on the visitor's activity on your website</li>
                        <li>"Le Chat" subscription added which gives access to all the features from above</li>
                    </ul>
                </article>


                <div className='parallelogramSeparator'>
                </div>
                <article>
                    <h2>Web App v2.1, June, 2024</h2>
                    <ul>
                        <li>widget avatar upload file and URL</li>
                        <li>user typing activity indicator</li>
                    </ul>
                </article>
                <div className='parallelogramSeparator'>
                </div>
                <article>
                    <h2>Web App v2.0, June, 2024</h2>
                    <ul>
                        <li>search chats</li>
                        <li>open, close, delete chats</li>
                        <li>see visitor's online status</li>
                        <li>seee visitor's current URL on your website</li>
                        <li>see all the visitors, export them including emails if provided by visitors</li>
                        <li>Wordpress plugin for the widget isntallation</li>
                        <li>widget customisation:</li>
                        <li><ul>
                            <li>change the main and accent colors</li>
                            <li>change the greeting message</li>
                            <li>change asking for the email messafe</li>
                            <li>change the button label</li>
                            <li>custom avatar</li>
                        </ul></li>
                        <li>"Just Chat" subcription is available</li>
                        <li>reset password</li>
                        <li>delete account and all data</li>
                    </ul>
                </article>
                <div className='parallelogramSeparator'>
                </div>
                <article>
                    <h2>iOS and Android app v1.1, May, 2024</h2>
                    <ul>
                        <li>search chats</li>
                        <li>open, close, delete chats</li>
                        <li>see visitor's online status</li>
                        <li>seee visitor's current URL on your website</li>
                        <li>setting to change the main and accent colors of your Le Chat widget</li>
                    </ul>
                </article>

            </section>
        </main>
    )
}
