// import { ui } from '../static/ui.js'
// import { useRecoilState } from 'recoil'
// import { languageState } from '../state.js'
// import {useNavigate} from 'react-router-dom'

function PrivacyPolicy() {
    // const [lang, setLang] = useRecoilState(languageState)
    // const navigate = useNavigate()
    return (
        <main>
			{/* <section>
				<div id='parallelogram' className='logo' onClick={()=>navigate('/')}>
					<h1 className='appName'>{ui[lang].siteTitle}</h1>
					<h2 className='appSlogan desktop'>{ui[lang].slogan}</h2>
				</div>
				<h3 className='mobile'>website live chat with a mobile app</h3>
			</section> */}

        <section className='textView'>
            <h1>Privacy Policy for <span className='boldText'>Le Chat Mobile App</span> <br/>by Arms and Legs FOM SL</h1>

            <p>Effective Date: May 28, 2024</p>

            <p>At Arms and Legs FOM SL, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our mobile app, Le Chat, designed for website managers to engage in real-time conversations with website visitors.</p>

            <h2>1. Information We Collect</h2>

            <h3>1.1 User-Provided Information</h3>
            <p>When you use the Le Chat mobile app, you may provide us with:</p>

            <ul>
                <li>Login Information: such as your username and password (encrypted).</li>
                <li>User Content: including chat messages exchanged between you and your website visitors.</li>
            </ul>

            <h3>1.2 Automatically Collected Information</h3>
            <p>We do not collect any additional data from your device beyond what is necessary for the app to function.</p>

            <h2>2. How We Use Your Information</h2>

            <h3>2.1 To Provide and Maintain Our Service</h3>
            <p>We use the collected data to:</p>

            <ul>
                <li>Enable you to log in and access your account.</li>
                <li>Facilitate real-time chat interactions between you and your website visitors.</li>
                <li>Store chat conversations securely in our database.</li>
            </ul>

            <h3>2.2 No Data Tracking or Additional Use</h3>
            <p>We do not track your data or use it in any way beyond what is necessary to provide our service. We do not collect any additional data from you or your device.</p>

            <h2>3. Data Storage and Retention</h2>

            <h3>3.1 Data Storage</h3>
            <p>All chat conversations and associated data are stored securely in our database. We implement appropriate security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.</p>

            <h3>3.2 Data Retention</h3>
            <p>We retain your chat conversations until you choose to delete them. You have full control over the deletion of your chat history.</p>

            <h2>4. Data Sharing and Disclosure</h2>

            <h3>4.1 No Third-Party Sharing</h3>
            <p>We do not share your data with any third-party service providers, partners, or external vendors. Your data remains private and secure within our system.</p>

            <h3>4.2 Legal Requirements</h3>
            <p>We may disclose your data if required to do so by law or in response to valid requests by public authorities.</p>

            <h2>5. Your Privacy Rights</h2>

            <h3>5.1 Access and Control Over Your Data</h3>
            <p>You have the right to:</p>

            <ul>
                <li>Access and update your personal information.</li>
                <li>Request the deletion of your chat conversations at any time.</li>
            </ul>

            <p>To exercise these rights, please contact us at support@lechat.app.</p>

            <h2>6. Security of Your Data</h2>

            <p>We are committed to protecting your data. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

            <h2>7. Changes to This Privacy Policy</h2>

            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date at the top. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2>8. Contact Us</h2>

            <p>If you have any questions about this Privacy Policy, please contact us:</p>

            <ul>
                <li>Company Name: Arms and Legs FOM SL</li>
                <li>Email: support@lechat.app</li>
                <li>Address: c/ Paris 157, 08036, Barcelona, Spain</li>
            </ul>

            <p>By using the Le Chat mobile app, you agree to the collection and use of information in accordance with this Privacy Policy. Thank you for trusting Arms and Legs FOM SL with your real-time customer support needs.</p>
        </section>
    </main>
    );
}

export default PrivacyPolicy;

