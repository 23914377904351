function TermsOfuse() {

    return (
        <main>
            <section className='textView'>
                <h1>Terms of Use</h1>
                <p><strong>Last Updated: June 9, 2024</strong></p>

                <p>Welcome to Le Chat! These Terms of Use ("Terms") govern your access to and use of the Le Chat website chat widget and associated services (collectively, the "Service") provided by Arms and Legs FOM SL ("Company", "we", "us", or "our"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Service.</p>

                <h2>1. Acceptance of Terms</h2>
                <p>By using the Service, you affirm that you are at least 18 years old and are fully able and competent to enter into and comply with these Terms. If you are using the Service on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.</p>

                <h2>2. Description of Service</h2>
                <p>Le Chat is a website chat widget that enables communication between website visitors and the website owner or their representatives. The Service is provided on an "as is" and "as available" basis. We strive to maintain the Service and keep it operational; however, we do not guarantee continuous, uninterrupted access to the Service, and there may be times when the Service is unavailable due to maintenance, updates, or other reasons.</p>

                <h2>3. Free Trial and Subscriptions</h2>
                <p>We offer a 30-day free trial of the Service with no credit card required. After the free trial period, you must subscribe to one of our available subscription plans to continue using the Service. Subscription details, including pricing and features, are available on our website.</p>

                <h2>4. Maintenance and Updates</h2>
                <p>We reserve the right to perform maintenance work on the Service as needed. During such periods, the Service may be temporarily unavailable. We will endeavor to perform maintenance during off-peak hours to minimize disruption.</p>

                <h2>5. Software Bugs and Crashes</h2>
                <p>While we make every effort to provide a reliable and stable Service, you acknowledge that the software may contain bugs and errors, and that crashes may occur. We are not liable for any damages or losses resulting from such bugs or crashes. However, we commit to responding to and addressing any issues as promptly as possible.</p>

                <h2>6. User Conduct</h2>
                <p>You agree to use the Service only for lawful purposes and in accordance with these Terms. You are prohibited from using the Service:</p>
                <ul>
                    <li>To engage in any unlawful or fraudulent activity;</li>
                    <li>To transmit any harmful, threatening, defamatory, obscene, or otherwise objectionable content;</li>
                    <li>To interfere with or disrupt the integrity or performance of the Service.</li>
                </ul>

                <h2>7. Intellectual Property</h2>
                <p>All content, trademarks, service marks, trade names, logos, and other intellectual property displayed on or through the Service are the property of Arms and Legs FOM SL or its licensors. You are granted no rights or licenses to use any of the foregoing.</p>

                <h2>8. Limitation of Liability</h2>
                <p>To the fullest extent permitted by applicable law, Arms and Legs FOM SL shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
                <ul>
                    <li>Your use of or inability to use the Service;</li>
                    <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
                    <li>Any interruption or cessation of transmission to or from the Service.</li>
                </ul>

                <h2>9. Indemnification</h2>
                <p>You agree to indemnify, defend, and hold harmless Arms and Legs FOM SL, its affiliates, officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from:</p>
                <ul>
                    <li>Your use of and access to the Service;</li>
                    <li>Your violation of any term of these Terms;</li>
                    <li>Your violation of any third-party right, including without limitation any right of privacy or intellectual property.</li>
                </ul>

                <h2>10. Changes to the Terms</h2>
                <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of the Service after the posting of the revised Terms constitutes your acceptance of the changes.</p>

                <h2>11. Termination</h2>
                <p>We reserve the right to terminate or suspend your access to the Service at our sole discretion, without prior notice or liability, for any reason whatsoever, including but not limited to a breach of these Terms.</p>

                <h2>12. Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of Spain, without regard to its conflict of law principles.</p>

                <h2>13. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@lechat.app">support@lechat.app</a>.</p>

                <p>By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

            </section>
        </main>
    );
}

export default TermsOfuse;

