import React from 'react';

const WPplugin = () => {
    return (
        <div>
            <h1>Le Chat WordPress Plugin</h1>
            <p>Welcome to the Le Chat WordPress plugin page!</p>
            <p>Here, you can find instructions on how to install and configure the Le Chat widget on your WordPress website.</p>
            {/* Add your installation instructions and configuration options here */}
        </div>
    );
};

export default WPplugin;