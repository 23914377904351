import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from './views/Home.js'
import NavBar from './components/NavBar.js'
import LogoHome from './components/LogoHome.js'
import PrivacyPolicy from './views/PrivacyPolicy.js'
import TermsOfUse from './views/TermsOfUse.js'
import Ideology from './views/Ideology.js'
import ChangesLog from './views/ChangesLog.js'
import FeatureRequest from './views/FeatureRequest.js'
import Ethics from './views/Ethics.js'
import Footer from './components/Footer.js'
import WPplugin from './views/WPplugin.js'

import './App.css'

function App() {


  return (
    <Router>
      {/* <NavBar /> */}
      <LogoHome />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path='/ideology' element={<Ideology />} />
          <Route path='/ethics' element={<Ethics />} />
          <Route path='/changes-log' element={<ChangesLog />} />
          {/* <Route path='/feature-request' element={<FeatureRequest />} /> */}
          <Route path="/le-chat-wordpress-plugin" element={<WPplugin />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
