export default function Ethics() {
    return (
        <main>
            <section className='textView'>
                <h1>Ethics statement</h1>
                <h2>Commitment to Privacy</h2>
                <p>At Le Chat, we place the utmost importance on the privacy and security of our users' data. We have a strict policy of never selling your data to anyone, under any circumstances. Your trust is our priority, and we are committed to maintaining the confidentiality of your information.</p>

                <h2>No Collection of Private Data</h2>
                <p>We respect the privacy of your website visitors. Le Chat does not collect any metadata or private information from your visitors. Our focus is on facilitating seamless communication, not on gathering data. You can rest assured that the interactions through our chat widget remain private and secure.</p>

                <h2>Transparency and Integrity</h2>
                <p>We believe in operating with complete transparency and integrity. Our practices are designed to reflect our commitment to ethical behavior and to building a service that you can trust, ensuring that our operations are straightforward and honest.</p>

                <h2>Our Promise</h2>
                <p>We promise to always put our users' interests first. Our ethical guidelines are in place to protect your data, respect your privacy, and provide you with a reliable and trustworthy service. We believe in doing the right thing and fostering a positive relationship with our users.</p>

                <p>At Le Chat, we are more than just a chat service. We are a company built on values of trust, privacy, and integrity. We are committed to maintaining these values in all aspects of our business, ensuring that you can use our service with confidence and peace of mind.</p>


            </section>
        </main>
    )
}
