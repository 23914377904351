
export default function TrustedBy() {
  return (
    <section>
        <h2>Trusted by</h2>
        <div style={styles.flexFluidGaller}>
            <img style={styles.singleImage50} src="https://barcelonacodeschool.com/static/BCS-LOGO-1000px-ddd4c04fd3a5549e151861ba8cdc56b2.jpg" alt="Barcelona Code School" />
            <img style={styles.singleImage50} src="/images/undefeatedSoftware.png" alt="Undefeated Software" />
        </div>
    </section>
  )
}

const styles = {
    flexFluidGaller: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '1rem',
    },
    singleImage50: {
        width: '50px',
        flex: '0 0 50px',
        filter: 'grayscale(100%)',
    }
}
