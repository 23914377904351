import { useState, useEffect } from 'react'
// import { useRecoilState } from 'recoil'
// import { languageState } from '../state.js'

// import EarlyAccess from '../components/EarlyAccess'
import WhatIsLeChat from '../components/WhatIsLeChat.js'
import TrustedBy from '../components/TrustedBy.js'

// import { ui } from '../static/ui.js'

function Home(props) {

	// const [lang, setLang] = useRecoilState(languageState)
	const [buttonDisabled, setButtonDisabled] = useState(false)

	const handleButtonClick = () => {
		// Track the conversion event
		if (window.gtag) {
			console.log('convert')
		  window.gtag('event', 'conversion', {
			'send_to': 'conversion_event_subscribe_paid'
		  });
		}
	
		// Redirect to the target URL
		window.location.href = 'https://dashboard.lechat.app/';
	  };

	return (
		<main>
			{/* <section>
				<div id='parallelogram' className='logo'>
					<h1 className='appName'>{ui[lang].siteTitle}</h1>
					<h2 className='appSlogan desktop'>{ui[lang].slogan}</h2>
				</div>
				<h3 className='mobile'>website live chat with a mobile app</h3>
			</section> */}

			<div className='parallelogramSeparator'>
			</div>

			<div className='inlineFlexContainer'>
				<a href='https://dashboard.lechat.app'><p id='webDashboardBadge'>Web&nbsp;Dashboard</p></a>
				<a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.lechat' target='_blank' rel="noreferrer"><img src='/images/en_badge_web_generic.png' alt='Google Play Store badge' className='badge' /></a>
				<a href='https://apps.apple.com/es/app/le-chat-client/id6476455098?l=en-GB' target='_blank' rel="noreferrer"><img src='/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt='App Store badge' className='badge' /></a>
			</div>

			<div className='parallelogramSeparator'>
			</div>

			<section>
				<p>Add a chat to any website to let your customers talk directly to you</p>
				<p>Respond from your dashboard or from the mobile app wherever you are</p>
				<p>Available for web, iOS and Android</p>
			</section>

			<div className='parallelogramSeparator'>
			</div>

			<section>
				<h2>Choose your plan</h2>
				<div className='grid2'>

					<article className='planContainer'>
						<div>
							<h3>Just Chat</h3>
							<p>Let customers chat with you in real-time from your website</p>
							<ul className='leftAligned'>
								<li>Chat widget to easily add to your website</li>
								<li>Web dashboard to see all your chats and interact with your customers</li>
								<li>Mobile app for iOS and Android to chat with your customers wherever you are</li>
								<li>Real-time notifications for the new messages (mobile app, email, in-browser)</li>
								<li>Capture customers emails and export to csv</li>
								<li>Chats statistics</li>
								<li>"Powered by Le Chat" label</li>
							</ul>
						</div>
						<div className='centeredText'>
						<h4>19.99€/month</h4>
						<button onClick={handleButtonClick} disabled={buttonDisabled}>Start 30-day trial</button>
						<p className='tinyText'>no card required</p>
						</div>
					</article>

					<article className='planContainer'>
						<div>
							<h3>Le Chat</h3>
							<p>Unlock the power of automation and proactive engagement</p>
							<ul className='leftAligned'>
								<li>All the features from "Just Chat"</li>
								<li>Easily set up the chatbot to answer automaticaly predefined repetitive questions your visitors ask</li>
								<li>Use saved replies to quickly insert a reply to a commonly repeated question</li>
								<li>Set up playbooks to engage with your visitors proactively</li>
								<li>Remove "Powered by Le Chat" from your widget</li>
							</ul>
						</div>
						<div className='centeredText'>
						<h4>29.99€/month</h4>
						<button onClick={handleButtonClick} disabled={buttonDisabled}>Start 30-day trial</button>
						<p className='tinyText'>no card required</p>
						</div>
					</article>

					{/* <article className='planContainer'>
		<div>
		<h3>Mega Chat</h3>
		<p>For bigger companies – send users to different teams for chats</p>
		<ul className='leftAligned'>
		<li>All the features from "Just Chat" and "Le Chat"</li>
		<li>Set up teams to send your visitor to chat with a team responsible for the area in question</li>
		</ul>
		</div>
		<h4>49.99€/month</h4>
		</article> */}


				</div>
			</section>

			<div className='parallelogramSeparator'>
			</div>

			<TrustedBy />

			<div className='parallelogramSeparator'>
			</div>
			{/* <EarlyAccess/> */}

			{/* <div className='parallelogramSeparator'>
		</div> */}

			<WhatIsLeChat />

			<div className='parallelogramSeparator'>
			</div>

		</main>
	)
}


export default Home
