import React from 'react'
const screenshot_noChat = './images/screenshots/website_no_chat.jpg'
const screenshot_chatClosed = './images/screenshots/website_with_chat_closed.jpg'
const screenshot_chatGreeting = './images/screenshots/website_whith_chat_greeting.jpg'
const screenshot_chatConvo = './images/screenshots/website_with_chat_convo.jpg'
const screenshot_dashboard = './images/screenshots/leChat_dashboard_screenshot.png'
const arrow_down = './images/icons/arrow_down.png'

export default function WhatIsLeChat() {
	return (
		<section className='whatIsLeChat'>
		<h2 className='bottomSpacer'>What is a website chat?</h2>
		<div className='grid2'>

		<div><article>
				<p className='textRightAligned'>You have a website. How do your users get in touch with you?</p>
				<p className='textRightAligned'>You probably have some contact information there... Or even a contact form...</p>
				<p className='textRightAligned'>How about giving them a chance to get in touch instantly? Would you like that? </p>
				</article></div>
		<div><img className='screenshot' src={screenshot_noChat}/><img src={arrow_down} className='mobile icon'/></div>


		<div><img className='screenshot' src={screenshot_chatClosed}/><img src={arrow_down} className='mobile icon'/></div>
		<div><article>
				<p className='textLeftAligned'>By installing Le Chat on your website you will have a small floating chat widget in the bottom right corner of your website always visible to your customers.</p>
				<p className='textLeftAligned'>The bottom right corner is chosen because according to web design standards conventionally it almost never contains any meaningful information for a user.</p>
				</article></div>





		<div><article>
				<p className='textRightAligned'>All it takes for a user is to click on the chat icon to open up a conversation.</p>
				<p className='textRightAligned'>It can also be set to open up automatically and invite user to start a chat with custom PlayBooks you can define from your Le Chat dashboard.</p>
				</article></div>
		<div><img className='screenshot' src={screenshot_chatGreeting}/><img src={arrow_down} className='mobile icon'/></div>

		

		<div><img className='screenshot' src={screenshot_chatConvo}/><img src={arrow_down} className='mobile icon'/></div>
		<div><article>
				<p className='textLeftAligned'>Once your customer started the conversation you will be notified via email and mobile app (available for iOS and Android) so you can join the chat immediately and assist your customer right away.</p>
				<p className='textLeftAligned'>Thus with Le Chat you provide live real-time support to your visitors and another way for you to turn them into your beloved customers.</p>
				</article></div>



		<div><article>
				<p className='textRightAligned'>You can join the conversations from either the mobile app or from the web-based complete Le Chat dashboard.</p>
				</article></div>
		<div><img className='screenshot' src={screenshot_dashboard}/></div>


		</div>
		</section>
		)
}