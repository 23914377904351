import { ui } from '../static/ui.js'
import { useRecoilState } from 'recoil'
import { languageState } from '../state.js'
import { useNavigate } from 'react-router-dom'

export default function LogoHome() {
    const [lang, setLang] = useRecoilState(languageState)
    const navigate = useNavigate()
    return (
        <section>
            <div id='parallelogram' className='logo' onClick={() => navigate('/')}>
                <h1 className='appName'>{ui[lang].siteTitle}</h1>
                <h2 className='appSlogan desktop'>{ui[lang].slogan}</h2>
            </div>
            <h3 className='mobile'>website live chat with a mobile app</h3>
        </section>
    )
}
