export default function Ideology() {
    return (
        <main>
            <section className='textView'>
                <h1>Our Ideology</h1>

                <h2>Ease of Use</h2>
                <p>At Le Chat, we prioritize simplicity and accessibility. Our service is designed to be user-friendly, allowing for easy integration with your website. Whether you are using our web dashboard or our mobile apps available for both iOS and Android, managing your chats and interacting with your visitors is seamless and intuitive.</p>

                <h2>Transparency with Our Users</h2>
                <p>We believe in maintaining an open and transparent relationship with our users. To keep you informed, we regularly publish a detailed changelog that outlines updates and improvements to our service. Additionally, we have a dedicated feature request page where you can suggest new features and enhancements. Your feedback is invaluable to us, and we actively consider your suggestions to make Le Chat even better.</p>

                <h2>Development Vector</h2>
                <p>Our development efforts are focused on providing tailored chat solutions that meet diverse needs. We offer three distinct plans:</p>
                <ul>
                    <li><strong>Just Chat:</strong> Our core plan that focuses on essential chat functionality, ensuring reliable and efficient communication with your website visitors.</li>
                    <li><strong>Le Chat:</strong> An upcoming plan that introduces additional useful features such as saved replies, automatic replies, and playbooks to enhance your chat interactions.</li>
                    <li><strong>Mega Chat:</strong> A comprehensive plan that includes all features of "Le Chat" along with an advanced AI assistant to provide intelligent and automated responses, improving your customer service experience.</li>
                </ul>

                <h2>Customer-Centric Approach</h2>
                <p>We are dedicated to providing excellent customer support and ensuring user satisfaction. Our support team is always ready to assist you with any questions or issues you might encounter, ensuring that you have a smooth and productive experience with Le Chat.</p>

                <h2>Innovation and Continuous Improvement</h2>
                <p>Innovation is at the heart of what we do. We continuously seek to improve our service by incorporating the latest technologies and staying ahead of industry trends. Our goal is to offer a cutting-edge chat solution that evolves with the changing needs of our users.</p>

                <p>At Le Chat, our mission is to make communication easy, transparent, and innovative. We are committed to continually improving our service to meet your needs and exceed your expectations.</p>

            </section>
        </main>
    )
}
