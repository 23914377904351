import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { handleNavigation } from '../utils/handleNavigation.js';
import {version} from '../static/statics.js';

export default function Footer() {
    const navigate = useNavigate();
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();

    return (
        <footer>
            <h4 onClick={() => handleNavigation(navigate, '/')} className='clickable'>Le Chat | v{version} | {`${currentMonth} ${currentYear}`}</h4>
            <div className='grid3'>
                <article>
                    <p>Arms and Legs FOM SL</p>
                    <p>c/ Paris 157, 08036, Barcelona, Spain</p>
                    <p><a href='mailto:support@lechat.app'>support@lechat.app</a></p>
                </article>
                <article>
                    <p onClick={() => handleNavigation(navigate, '/privacy-policy', false)} className='clickable'>Privacy Policy</p>
                    <p onClick={()=>handleNavigation(navigate, '/terms-of-use', false)} className='clickable'>Terms of Use</p>
                    <p onClick={() => handleNavigation(navigate, 'https://apps.apple.com/es/app/le-chat-client/id6476455098?l=en-GB', true)} className='clickable'>iOS app</p>
                    <p onClick={()=>handleNavigation(navigate, 'https://play.google.com/store/apps/details?id=com.barcelonacodeschool.lechat', true)} className='clickable'>Android app</p>
                </article>
                <article>
                    <p onClick={()=>handleNavigation(navigate, '/ideology', false)} className='clickable'>Our ideology</p>
                    <p onClick={()=>handleNavigation(navigate, '/ethics', false)} className='clickable'>Ethics statement</p>
                    {/* <p onClick={()=>handleNavigation(navigate, '/feature-request', false)} className='clickable'>New features request</p> */}
                    <p onClick={()=>handleNavigation(navigate, '/changes-log', false)} className='clickable'>Changes log</p>
                </article>
            </div>
        </footer>
    );
}
